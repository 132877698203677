import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { IdbService } from './idb.service';
import { Command } from 'app/models/command';
import { ToolboxService } from 'app/toolbox.service';
import { SiteReviewService } from './site-review.service';
import { map } from 'rxjs/operators';
import { RowComp } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { DataStoreService } from './datastore.service';
import { ChatterService } from 'app/chat/shared/services/chatter.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BulkLoaderService {

  constructor(private idb: IdbService, private tb: ToolboxService, private db: SiteReviewService, private dataStore: DataStoreService, private chatter: ChatterService, private router: Router) { }

  jobListTrigger = new BehaviorSubject(0)
  jobAuthTrigger = new ReplaySubject(1)
  percentage = new BehaviorSubject(0);
  public loader = new BehaviorSubject<number>(0);
  
  existingIds: Set<number> = new Set([]);
  loadedJobRecords = [];

  private batchSize = 100;
  private updateThreshold = 100;
  gridUpdatedCount = 0;

  get loader$() {
    return this.loader.asObservable();
  }


  async jsonBulkLoader(gridApi, gridOptions) {
    gridApi?.setRowData([] as any);
    this.loader.next(0);
    let progress = 0;

    const intervalId = setInterval(() => {
      progress += 20;
      this.loader.next(progress);
      if (progress >= 101) {
        clearInterval(intervalId);
      }
    }, 800);
    await this.idb.clearData('jobs');
    this.db.getJobsGoogleJson().subscribe({
      next: async (res) => {
        console.log("getJobsGoogleJson ", res.length);
        let jsonFile: any = res;
        gridOptions.localeText.noRowsToShow = 'Loading complete...';
        if (Array.isArray(jsonFile) && jsonFile.length > 0) {
          // make sure to eliminate duplicates
          this.existingIds.clear();
          const uniqueJobs = new Set();
          let filteredJobs = jsonFile.filter((newRow: any) => {
            if (!uniqueJobs.has(newRow.jobID)) {
              uniqueJobs.add(newRow.jobID);
              return true;
            }
            return false;
          });
          //console.log('Filtered jobs:', filteredJobs);

          filteredJobs.forEach((job: any) => {
            this.existingIds.add(job.jobID);
            this.formatBulkLoaderJobs(job);
          });

          if (filteredJobs.length > 0) {
            filteredJobs = await this.clearRows(gridApi, filteredJobs);
            console.log('Cleared rows:', filteredJobs.length);
            //gridApi?.setRowData(filteredJobs as any);
            await gridApi.applyTransactionAsync({ add: filteredJobs }, this.callback);
            console.log('Rows added:', filteredJobs.length);
            const today = new Date();
            const pstDateString = today.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
            localStorage.setItem('chunkerRunTodayPST', pstDateString); // Set the flag
            // then we update the local DB
            await this.idb.insertBulk('jobs', filteredJobs, async () => {
              console.log('cache bulk done inserting ' + filteredJobs.length + ' rows');            
              this.updateLoader(gridApi,true);
            });
          }
        } else {
          console.warn("Received empty or invalid JSON data");
          clearInterval(intervalId);
          this.loader.next(100);
          gridOptions.localeText.noRowsToShow = 'No Rows to Show';
        }
      },
      error: (error) => {
        console.error("Error in getJobsGoogleJson:", error);
        clearInterval(intervalId);
        this.loader.next(100);
      }
    });
  }

  formatBulkLoaderJobs(job) {

    if (`${job.pmNumber}` != '') { job.pMNumber = job.pmNumber; }

    if (`${job.waApprovalDate}` != '') { job.wAApprovalDate = job.waApprovalDate; }
    if (`${job.waCurrent}` != '') { job.wACurrent = job.waCurrent; }
    if (`${job.waCurrentStatus}` != '') { job.wACurrentStatus = job.waCurrentStatus; }
    if (`${job.waDelta}` != '') { job.wADelta = job.waDelta; }

    if (`${job.waSubmissionDate}` != '') { job.wASubmissionDate = job.waSubmissionDate; }
    if (`${job.waApprovalDate}` != '') { job.wAApprovalDate = job.waApprovalDate; }
    if (`${job.waDescription}` != '') { job.wADescription = job.waDescription; }
    if (`${job.waTotal}` != '') { job.wATotal = job.waTotal; }
    if (`${job.pgeFieldEngineer}` != '') { job.pGEFieldEngineer = job.pgeFieldEngineer; }
    if (`${job.poWRO}` != '') { job.pOWRO = job.poWRO; }
    if (`${job.waPricingStructure}` != '') { job.wAPricingStructure = job.waPricingStructure; }
    if (`${job.pcNotes}` != '') { job.pCNotes = job.pcNotes; }

    if (`${job.usaDone}` != '') { job.uSADone = job.usaDone; }

    if (`${job.pgeFieldEngineer}` != '') { job.pGEFieldEngineer = job.pgeFieldEngineer; }
    if (job.waPricingStructure != '') { job.wAPricingStructure = job.waPricingStructure; }
    if (job.qaFailCount != '') { job.qAFailCount = job.qaFailCount; }
    if (job.qaInitialScore != '') { job.qAInitialScore = job.qaInitialScore; }
    if (job.qaReceived != '') { job.qAReceived = job.qaReceived; }
    if (job.qaInitialReceivedDate != '') { job.qAInitialReceivedDate = job.qaInitialReceivedDate; }
    if (job.qaScore != '') { job.qAScore = job.qaScore; }
    if (job.qaStatus != '') { job.qAStatus = job.qaStatus; }
    if (job.qaSubmitted != '') { job.qASubmitted = job.qaSubmitted; }
    if (job.qaReviewType != '') { job.qAReviewType = job.qaReviewType; }
    if (job.qaPassFail != '') { job.qAReviewType = job.qaPassFail; }
    if (job.waCurrent != '') { job.wACurrent = job.waCurrent; }
    if (job.copReceivedDate != '') { job.cOPReceivedDate = job.copReceivedDate; }
    if (job.copSubmittedDate != '') { job.cOPSubmittedDate = job.copSubmittedDate; }
    if (job.w1S != '') { job.w1s = job.w1S; }
    if (job.w2S != '') { job.w2s = job.w2S; }
    if (job.w3S != '') { job.w3s = job.w3S; }
    if (`${job.lmeComplete}` != '') { job.lMEComplete = job.lmeComplete; }
    if (job.updatedDate === null || job.updatedDate === undefined || job.updatedDate === '') {
      job.updatedDate = new Date(job.jobCreatedDate);
    } else {
      job.updatedDate = new Date(job.updatedDate);
    }
  }

  async clearRows(gridApi, rows) {
    const rowNodesMap = new Map();
    gridApi.forEachNode(node => {
      rowNodesMap.set(String(node.data.jobID), node);
    });
  
    var filteredRecs = [];
  
    rows.forEach(row => {
      const node = rowNodesMap.get(String(row.jobID));
      if (node) {
        gridApi.applyTransaction({ remove: [node.data] });
      } else {
        filteredRecs.push(row);
      }
    });
    return filteredRecs;
  }
  
  async updateLoader(gridApi, forceexpire = false) {
    try {

      let ed: Date = new Date("1/1/2024");
      const dbLastModDate = await this.idb.updateStatus('first');
      
      if (forceexpire) {
        let d = new Date();
        ed = new Date(d.getFullYear(), d.getMonth(), d.getDate(),5);
      } else {
        if (dbLastModDate.length === 0) {
          return null;
        }
        ed = new Date(dbLastModDate[0]);
      }
      const sqlExpireDate: string = ed.toLocaleDateString() + ' ' + ed.toLocaleTimeString();
      console.log('retrieving cmdJobsMasterUpdateLimited data starting ' + sqlExpireDate);
  
      let cmd = new Command();
      cmd.procedure = "cmdJobsMasterUpdateLimited";
      cmd.addParameter("ExpireDate", sqlExpireDate);
      const JobsMasterULResponse = await this.db.command(cmd).toPromise();
  
      // Insert bulk data into 'jobs' table
      await this.idb.insertBulk('jobs', JobsMasterULResponse, async () => {
        console.log('done inserting/updating ', JobsMasterULResponse.length, ' rows ', JobsMasterULResponse);
      });
      
      // Clean up
      await this.cleanDeletes(sqlExpireDate, gridApi);

      //await gridApi.applyTransactionAsync({ update: JobsMasterULResponse });
      await this.applyTransactionUpdates(gridApi, JobsMasterULResponse);
  
      // Run gridSync with little delay to allow the indexedDB to update
      setTimeout(async () => {
        await this.gridSync(gridApi);
      }, 2000);
    } catch (error) {
      console.error('Error in updateLoader:', error);
    }
  }

  async gridSync(gridApi: any) {
    if (!gridApi) return;
  
    const indexedDBJobs = await this.idb.list('jobs');
    console.log('gridSync indexedDBJobs', indexedDBJobs.length)
  
    await this.applyTransactionUpdates(gridApi, indexedDBJobs);
  }

  async applyTransactionUpdates(gridApi, rows) {
    const startMillis = new Date().getTime();
    this.gridUpdatedCount = 0;

    // Create a map of current row nodes for quick access
    const rowNodesMap = new Map();
    gridApi.forEachNode(node => {
        if (node.data?.jobID != null) {
            rowNodesMap.set(String(node.data.jobID), node);
        }
    });

    const toAdd = [];
    const toUpdate = [];

    // Prepare the data for adding and updating
    for (const row of rows) {
        if (!row?.jobID) continue;

        const jobID = String(row.jobID);
        const rowNode = rowNodesMap.get(jobID);

        if (!rowNode) {
            // New row to add
            toAdd.push(row);
            continue;
        }

        // Check for changes
        const changes = this.getChanges(rowNode.data, row);
        if (changes) {
            toUpdate.push({ rowNode, changes });
        }
    }

    // Batch add new rows
    if (toAdd.length > 0) {
        console.log('applyTransactionUpdates transaction toAdd', toAdd);
        gridApi.applyTransactionAsync({ add: toAdd }, this.resultCallback(startMillis));
    }

    // Process updates in batches
    if (toUpdate.length > 0) {
      const totalUpdates = toUpdate.length;
  
      for (let i = 0; i < totalUpdates; i += this.batchSize) {
          const batchUpdates = toUpdate.slice(i, i + this.batchSize);
          const updatedRows = [];
          const rowNodes = [];
  
          for (const { rowNode, changes } of batchUpdates) {
              if (changes) {
                  const updatedData = { ...rowNode.data, ...changes };
                  if (JSON.stringify(rowNode.data) !== JSON.stringify(updatedData)) {
                      rowNode.setData(updatedData); // Update only if there's a change
                      updatedRows.push(updatedData);
                      rowNodes.push(rowNode);
                  }
              }
          }
  
          // Refresh cells only if there are actual updates
          if (rowNodes.length > 0) {
              gridApi.refreshCells({ rowNodes, force: false }); // Consider using force: false
              try {
                console.log('gridSync transaction updatedRows', updatedRows.length);
                gridApi.applyTransactionAsync({ update: updatedRows }, this.resultCallback(startMillis));
              } catch (err) {
                  console.warn('Group update warning:', err);
              }
          }
  
          if (i + this.batchSize < totalUpdates) {
              await new Promise(resolve => setTimeout(resolve, this.updateThreshold));
          }
      }
    }
  }

  resultCallback(startMillis) {
    this.gridUpdatedCount++;
    if (this.gridUpdatedCount === 500) {
      // print message in next VM turn to allow browser to refresh
      setTimeout(() => {
        const endMillis = new Date().getTime();
        const duration = endMillis - startMillis;
        console.log("Async took " + duration.toLocaleString() + "ms");
      }, 0);
    }
  }


  // async gridSync(gridApi: any) {
  //   if (!gridApi) return;
  
  //   const indexedDBJobs = await this.idb.list('jobs');
  //   console.log('gridSync indexedDBJobs', indexedDBJobs.length)
  
  //   await this.applyTransactionUpdates(gridApi, indexedDBJobs);
  // }

  // async applyTransactionUpdates(gridApi, rows) {
  //   const rowNodesMap = new Map();
    
  //   // Create a map of current row nodes for quick access
  //   gridApi.forEachNode(node => {
  //       if (node.data?.jobID != null) {
  //           rowNodesMap.set(String(node.data.jobID), node);
  //       }
  //   });

  //   const toAdd = [];
  //   const toUpdate = [];

  //   // Prepare the data for adding and updating
  //   for (const row of rows) {
  //       if (!row?.jobID) continue;

  //       const jobID = String(row.jobID);
  //       const rowNode = rowNodesMap.get(jobID);

  //       if (!rowNode) {
  //           // New row to add
  //           toAdd.push(row);
  //           continue;
  //       }

  //       // Check for changes
  //       const changes = this.getChanges(rowNode.data, row);
  //       if (changes) {
  //           toUpdate.push({ rowNode, changes });
  //       }
  //   }

  //   // Batch add new rows
  //   if (toAdd.length > 0) {
  //       console.log('applyTransactionUpdates transaction toAdd', toAdd);
  //       await gridApi.applyTransactionAsync({ add: toAdd });
  //   }

  //   // Process updates in batches
  //   if (toUpdate.length > 0) {
  //       const totalUpdates = toUpdate.length;
        
  //       for (let i = 0; i < totalUpdates; i += this.batchSize) {
  //           const batchUpdates = toUpdate.slice(i, i + this.batchSize);
  //           const updatedRows = [];
  //           const rowNodes = [];

  //           for (const { rowNode, changes } of batchUpdates) {
  //               const updatedData = { ...rowNode.data, ...changes };
  //               rowNode.setData(updatedData); // Update the node's data immediately
  //               updatedRows.push(updatedData);
  //               rowNodes.push(rowNode);
  //           }

  //           // Refresh cells in batch
  //           gridApi.refreshCells({
  //               rowNodes,
  //               force: true
  //           });

  //           try {
  //               console.log('gridSync transaction updatedRows', updatedRows.length);
  //               await gridApi.applyTransactionAsync({ update: updatedRows });
  //           } catch (err) {
  //               console.warn('Group update warning:', err);
  //               // If transaction fails, at least the data is updated via setData
  //           }

  //           //Wait a bit before processing the next batch to avoid lag
  //           if (i + this.batchSize < totalUpdates) {
  //               await new Promise(resolve => setTimeout(resolve, this.updateThreshold));
  //           }
  //       }
  //   }
  // }


  // async applyTransactionUpdates(gridApi, rows) {
  //   const rowNodesMap = new Map();
  //   gridApi.forEachNode(node => {
  //     if (node.data?.jobID != null) {
  //       rowNodesMap.set(String(node.data.jobID), node);
  //     }
  //   });
  
  //   const toAdd = [];
  //   const toUpdate = [];
  
  //   for (const row of rows) {
  //     if (!row?.jobID) continue;
  
  //     const jobID = String(row.jobID);
  //     const rowNode = rowNodesMap.get(jobID);
  
  //     if (!rowNode) {
  //       // New row to add
  //       toAdd.push(row);
  //       continue;
  //     }
  
  //     // Check for changes
  //     const changes = this.getChanges(rowNode.data, row);
  //     if (changes) {
  //       toUpdate.push({ rowNode, changes });
  //     }
  //   }
  
  //   // Apply batch updates
  //   if (toAdd.length > 0) {
  //     console.log('applyTransactionUpdates transaction toAdd', toAdd)
  //     await gridApi.applyTransactionAsync({ add: toAdd });
  //   }
  
  //   if (toUpdate.length > 0) {
  //     const updatedRows = [];
  //       const rowNodes = [];
        
  //       for (const { rowNode, changes } of toUpdate) {
  //         const updatedData = { ...rowNode.data, ...changes };
  //         rowNode.setData(updatedData);
  //         rowNodes.push(rowNode);
  //         updatedRows.push(updatedData);
  //       }

  //       gridApi.refreshCells({
  //         rowNodes,
  //         force: true
  //       });

        
  //       try {
  //         console.log('gridSync transaction updatedRows', updatedRows.length)
  //         await gridApi.applyTransactionAsync({ update: updatedRows });
  //       } catch (err) {
  //         console.warn('Group update warning:', err);
  //         // If transaction fails, at least the data is updated via setData
  //       }
  //   }
  // }

  getChanges(gridData, dbData) {
    const changes = {};
    let hasChanges = false;

    for (const key in dbData) {
      if (!dbData.hasOwnProperty(key)) continue;
      
      const gridValue = gridData[key];
      const dbValue = dbData[key];

      if (this.isDateField(key)) {
        if (!this.areDatesEqual(gridValue, dbValue)) {
          changes[key] = dbValue;
          hasChanges = true;
        }
      } else if (gridValue !== dbValue) {
        changes[key] = dbValue;
        hasChanges = true;
      }
    }
    return hasChanges ? changes : null;
  }
  

  isDateField(key) {
    return key === 'updatedDate' || key.toLowerCase().includes('date');
  }

  // Helper function to compare dates
  areDatesEqual(date1: Date | string, date2: Date | string): boolean {
    const d1 = date1 instanceof Date ? date1 : new Date(date1);
    const d2 = date2 instanceof Date ? date2 : new Date(date2);
    return d1.getTime() === d2.getTime();
  }

  async cleanDeletes(startDate, gridApi) {
    //cmdJobMasterDeletes
    var cmd: Command = new Command();
    cmd.procedure = "cmdGetInactiveJobIds"
    cmd.addParameter("StartDate", startDate)
  
    try {
      const retval = await this.db.command(cmd).toPromise();
      // console.log('got deletes', retval);
  
      if (retval.length > 0) {
        const toRemove = [];
        const archives = [];
  
        const deletesString = retval[0].deleted;
        if (deletesString.indexOf(',') > 0) {
          toRemove.push(...deletesString.split(',').map(Number));
        } else if (deletesString.length > 0) {
          toRemove.push(parseInt(deletesString));
        }
  
        const archivedString = retval[0].archived;
        if (archivedString.indexOf(',') > 0) {
          archives.push(...archivedString.split(',').map(Number));
        } else if (archivedString.length > 0) {
          archives.push(parseInt(archivedString));
        }
  
        const allToRemove = [...toRemove, ...archives];
  
        allToRemove.forEach(async (jobID) => {
          const checkRec = this.dataStore.jobMaster.data.filter((obj) => obj.jobID !== jobID);
          if (checkRec.length > 0) {
            this.dataStore.jobMaster.data = this.dataStore.jobMaster.data.filter((obj) => obj.jobID !== jobID);
            await this.idb.simpleDelete('jobs', jobID);
            gridApi.applyTransaction({ remove: [{ jobID: jobID }] });
          }
        });
  
        // Remove error rows (if any)
        await this.idb.simpleDelete('jobs', -2);
        await this.idb.simpleDelete('jobs', -1);
      }
    } catch (error) {
      console.error('Error in cleanDeletes:', error);
    }
  }


  // old job master bulk loader
  async bulkLoader(gridApi) {
    let commands = [];
    await this.idb.clearData('jobs');
    this.existingIds = new Set([]);

    gridApi.setRowData([]);

    for (let y = 2019; y <= new Date().getFullYear(); y++) {
      if (y > 2022) {
        // Generate weekly intervals for the current year
        let currentDate = new Date(y, 0, 1);
        while (currentDate <= new Date()) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          endDate.setDate(endDate.getDate() + 7);
          if (endDate > new Date()) {
            endDate.setTime(new Date().getTime()); // Set to current date
          }
          endDate = this.setToEndOfDay(endDate);
          commands.push({ startDate, endDate });
          currentDate.setDate(currentDate.getDate() + 7);
        }
      } else {
        // Generate monthly intervals for previous years
        for (let m = 0; m <= 11; m++) {
          const d = new Date(y, m, 1);
          if (d <= new Date()) {
            console.log('date', d);
            let endDate;
            if (m === 11) {
              endDate = new Date(y + 1, 0, 1);
            } else {
              endDate = new Date(y, m + 1, 1);
            }
            // Set start date to 12:00 AM (midnight)
            const startDate = new Date(d);
            startDate.setHours(0, 0, 0, 0);
            // Set end date to the last minute of the month
            endDate.setHours(23, 59, 59, 999);
            commands.push({ startDate, endDate });
          }
        }
      }
    }

    // Now your `commands` array contains precise datetime intervals
    console.log(commands);
  }

  setToEndOfDay(dateTimeString: Date): Date | null {
    try {
      // Parse the input date string
      const parsedDate = new Date(dateTimeString);

      // Check if parsing was successful
      if (isNaN(parsedDate.getTime())) {
        console.error('Invalid date format. Please provide a valid date and time.');
        return null;
      }

      // Set the time to 11:59 PM
      parsedDate.setHours(23, 59, 0, 0);
      return parsedDate;
    } catch (error) {
      console.error('Error occurred while processing the date:', error);
      return null;
    }
  }

  private callback(e) {
    //console.log('callback reload', e)
  }


}
